
//サイズ
$spSize:675px;
$tbSize:1024px;
$pcSize:1400px;

//カラー
$base-color: #ff6633;
$base-color-red:#cc0000;
$base-color-gureen:#008842;
$base-color-black: #262424;
$base-color-blue:#0066cc;
$base-color-orange: #FD6633;
$bg-color:#f2f4f6;
