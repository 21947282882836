


@function ss($n,$a){
  @if $n == auto{
    @return $n;
  }@elseif $n == 0{
    @return 0;
  }@else{
    @if $a == sp{
      @return $n / 8 + vw;
    }@elseif $a == tb{
      @return $n / 14 + vw;
    }@elseif $a == pc{
      @return $n / 14 + vw;
    }@elseif $a == pcl{
      @return $n + px;
    }
  }
}

// font-size ///////////////////////////////////////////////
@mixin fs($s:14){
	font-size: ($s / 14) + rem;
}

@mixin baseContents($a:90%,$b:$pcSize){
  width: $a;
  max-width: $b;
  margin: 0 auto;
}

@mixin mgn($a:0,$b:$a,$c:$a,$d:$b){
  @media screen and (max-width: $spSize) {
    margin:ss($a,sp) ss($b,sp) ss($c,sp) ss($d,sp);
  }
  @media screen and (min-width: ($spSize + 1px)) and (max-width: $tbSize) {
    margin:ss($a,tb) ss($b,tb) ss($c,tb) ss($d,tb);
  }
  @media screen and (min-width: ($tbSize + 1px)) and (max-width: ($pcSize - 1px)) {
    margin:ss($a,pc) ss($b,pc) ss($c,pc) ss($d,pc);
  }
  @media screen and (min-width: $pcSize) {
    margin:ss($a,pcl) ss($b,pcl) ss($c,pcl) ss($d,pcl);
  }
}

@mixin pdg($a:0,$b:$a,$c:$a,$d:$b){
  @media screen and (max-width: $spSize) {
    padding:ss($a,sp) ss($b,sp) ss($c,sp) ss($d,sp);
  }
  @media screen and (min-width: ($spSize + 1px)) and (max-width: $tbSize) {
    padding:ss($a,tb) ss($b,tb) ss($c,tb) ss($d,tb);
  }
  @media screen and (min-width: ($tbSize + 1px)) and (max-width: ($pcSize - 1px)) {
    padding:ss($a,pc) ss($b,pc) ss($c,pc) ss($d,pc);
  }
  @media screen and (min-width: $pcSize) {
    padding:ss($a,pcl) ss($b,pcl) ss($c,pcl) ss($d,pcl);
  }
}



//bgcover
@mixin bgCover($img){
  background-image: url($img);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

@mixin clearfix(){
  &:after   {
    content: "";
    display: block;
    visibility: hidden;
    height: 0.1px;
    font-size: 0.1em;
    line-height: 0;
    clear: both;
    zoom: 1;
  }
}
