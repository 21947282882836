@charset "UTF-8";


@import "core/variable"; //変数設定
@import "core/functions"; //mixinの設定
@import "core/brackpoint"; //ブレイクポイント設定
@include mq-pc-tb{
};
@include mq-sp{  
};


@include mq-sp{  
  #breadcrumb{
    overflow: hidden;
    ul{
      width: 300%;
      padding-left: 2rem;
      li{
        white-space: nowrap
      }
    }
  }
};



._cm_title{
  width: 90%;
  max-width: 1200px;
  display: flex;
  align-items: center;
  @include mq-sp{
    flex-direction: column;
  };
  h2{
    margin-left: 2rem;
    color: #666;
  }
}



._article_container {
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
  @include pdg(35, 0);
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  @include mq-sp{  
    flex-direction: column
  };
}



._article_contents {
  width: 62%;

  @include mq-sp{
    width: 100%;
  };
  &.--w100{
    width: 100%;
  }
  &.--list {
    display: flex;
    flex-wrap: wrap;
    gap: 3rem 0;
    @include mq-sp{
      justify-content: space-between;
      padding-bottom: 3rem;
    };

    &>div {
      &.--column2 {
        width: 47.6%;
        
        @include mq-pc-tb{
          margin-right: 2%;

          &:nth-child(2) {
            margin-right: 0rem;
          }
        };
        @include mq-sp{
          width: 100%;
          margin-right: 0;
        };
        a {
          figure {
            height: 22rem;
          }
        }
      }

      &.--column3 {
        width: 31%;
        margin-right: 2%;

        a {
          figure {
            height: 15rem;
          }
        }

        @include mq-sp{
          width: 47%;
          a {
            figure {
              height: 12rem;
            }
          }
          ._article_cate_time{
            flex-direction: column;
          }
        };

      }

      a {
        display: block;
        @include fs(18);
        position: relative;

        h3 {
          line-height: 1.5;
        }

        p {
          &.--prt {
            position: absolute;
            transform: translate(-20%, -50%);
            top: 0;
            left: 0;
            z-index: 1;
          }
        }

        &:hover {

          h2,
          p {
            color: $base-color-gureen;
          }

          figure {
            i {
              transform: scale(1.1);
            }
          }
        }

        figure {
          width: 100%;
          height: 15rem;
          display: block;
          overflow: hidden;
          i {
            display: block;
            height: 100%;
            width: 100%;
            transition: 0.5s;
          }
        }


      }
    }

  }
  &.--detail{
    width: 60%;
    @include mq-sp{
      padding-bottom: 3rem;
    };

    @include mq-sp{
      width: 100%;
    };
  }
}

._article_side {
  width: 35%;

  @include mq-sp{
    width: 100%;
  };
  h2 {
    &:not(:first-child) {
      margin-top: 3rem;
    }

    &.--type1 {
      @include pdg(12, 0, 8);
      position: relative;
      border-bottom: 1px solid #ccc;
      @include fs(20);
      font-weight: 900;

      &:before,
      &:after {
        content: "";
        position: absolute;
        top: 0;
        height: 3px;
        background: #353535;
        border-radius: 100px;
      }

      &:before {
        background: $base-color-gureen;
        width: 20%;
        left: 0;
      }

      &:after {
        width: 78%;
        right: 0;
      }
    }
  }
}

._article_side_category {
  background: #FCFCFC;
  margin-top: 2rem;
  padding: 3rem;
  padding-top: 2rem;
  border-radius: 10px;

  h2 {
    @include fs(18);
    display: flex;
    align-items: center;
    line-height: 1;

    &:before {
      content: "";
      width: 1.8rem;
      height: 1.8rem;
      background-image: url("../images/column/ico_folder_green.svg");
      background-size: cover;
      background-position: center;
    }
  }

  ul {
    margin-top: 1rem;
    margin-left: 1rem;

    li {
      a {
        padding-left: 1.5rem;
        position: relative;
        @include fs(16);

        &:before {
          left: 0;
          top: 50%;
          content: "";
          position: absolute;
          width: 1rem;
          height: 1px;
          background: #ccc;
        }

        &:hover {
          color: $base-color-gureen;
        }
      }
    }
  }
}

._article_mv {
  h1 {
    @include fs(35);
    font-weight: 900;
    letter-spacing: 3px;
    line-height: 1.5;
    border-bottom: 1px solid #ccc;
    padding-bottom: 1rem;

    @include mq-sp{ 
      font-size: 2rem;
    };
  }

}

._article_cate_time {

  display: flex;
  justify-content: flex-start;
  gap: 0 1rem;
  margin-top: 1.1rem;

  @include mq-sp{
  };

  &.--detail{
    li{
      time{
        color: #333;
      }
      a{
        color: #333;
      }
    }
  }
  &.--mt {
    margin-top: 0.8rem;
  }

  li {
    @include fs(14);
    display: flex;
    line-height: 1;
    align-items: center;
    letter-spacing: 1px;

    color: #999999;

    &:before {
      content: "";
      width: 1.5rem;
      height: 1.5rem;
      background-image: url("../images/column/ico_clock_green.svg");
      background-size: cover;
      background-position: center;
    }

    &.--category {
      &:before {

        background-image: url("../images/column/ico_folder_green.svg");

      }
    }

    a,
    time,
    span {

      display: inline-block;
    }

  }

}

._article_side_contentslist {

  &.--runking {
    a {

      &:first-child,
      &:nth-child(2),
      &:nth-child(3) {
        &:before {
          content: "1";
          position: absolute;
          color: #fff;
          z-index: 2;
          @include fs(14);
          top:-1rem;
          left: -0.3rem;
        }
        &:after{
          z-index: 1;
          content: "";
          width: 2rem;
          height: 2rem;
          background: #FFBC00;
          position: absolute;
          left: 0;
          top:0;
          border-radius: 100%;
          transform: translate(-50%,-50%);
        }
      }
      &:nth-child(2){
        &:before{
          content: "2";
        }
        &:after{
          background: #87806E;
        }
        
      }
      &:nth-child(3){
        &:before{
          content: "3";
        }
        &:after{
          background: #CC6119;
        }
      }
    }
  }

  a {
    position: relative;
    margin-top: 1rem;
    display: flex;
    align-items: center;
    gap: 0 2rem;
    
    padding-bottom: 1rem;
    &:not(:last-child){
      border-bottom: 1px solid #ccc;
    }

    &:hover {
      .__block_info {
        p {
          color: $base-color-gureen;
        }
      }

      .__block_img {
        figure {
          i {
            transform: scale(1.1);
          }
        }
      }
    }

    .__block_img {
      width: 7rem;
      height: 7rem;

      figure {
        width: 100%;
        height: 100%;
        overflow: hidden;

        i {
          transition: .5s;
          width: 100%;
          height: 100%;
        }
      }
    }

    .__block_info {
      flex: 1;

      p {
        @include fs(16);
      }
    }

  }
}

._article_tags {
  display: flex;
  flex-wrap: wrap;
  margin-top: 1rem;
  &.--detail{
    a{
      color: $base-color-gureen;
      padding: 1rem;
      padding-top: 0;
      padding-left: 0;
    }
  }
  a {
    display: inline-block;
    padding: 0.2rem 1rem;
    padding-left: 0;
    @include fs(16);
    letter-spacing: 2px;


    &:hover {
      color: $base-color-gureen;
    }
  }
}

._article_pagenation {
  width: 90%;
  max-width: 1100px;
  margin: 0 auto;
}

._pagenation_new {
  display: flex;
  gap: 0 1rem;
  justify-content: center;
  align-items: center;
  padding-bottom: 3rem;

  a {
    display: inline-block;
    padding: 1rem 2rem;

    font-weight: 700;
    @include fs(18) background: #fff;
    border: 1px solid $base-color-gureen;
    color: $base-color-gureen;

    &.--current {
      background: $base-color-gureen;
      color: #fff;
    }
  }
}

._article_index{
  border-radius: 8px;
  background: #F7F7F7;
  padding: 2rem 0rem;
  margin-top: 2rem;

  @include mq-sp{
    padding-right: 2rem;
  };

  display: flex;
  .__title{
    width: 20%;
    display: flex;
    justify-content: center;
  
  }
  .__index{
    flex: 1;
    &.--action{
      .__wrap{
        &:before{
          display: none;
        }
      }
      .__button{
        @include mq-sp{ 
          margin-top: 1rem; 
        };
        p{
          i{
            &:before{
              opacity: 0;
              transform: translate(-50%,-100%);
            }
          }
        }
      }
    }
    .__wrap{
      overflow: hidden;
      height: 100px;
      transition: .5s;
      position: relative;
      &:before{
        content: "";
        width: 100%;
        height: 7rem;
        position: absolute;
        left:0;
        bottom:-2rem;
        background-repeat: repeat-x;
        background-image: url('../images/column/article_prt_01.png');
        background-position: bottom center;
        z-index: 3;
      }
    }
    .__button{
      text-align: center;
      p{
        
        line-height: 1;
        display: inline-block;
        border: 1px solid #333;
        padding: 0.8rem 2rem;
        padding-right: 3rem;
        cursor: pointer;
        position: relative;
        @include fs(16);

        i{
          position: absolute;
          left: 85%;
          top:50%;
          transform: translateY(-50%);
          &:before,
          &:after{
            content: "";
            position: absolute;
            transform: translate(-50%,-50%);
            background: #333;
            top: 50%;
            left: 50%;
            transition: .5s;
          }
          &:before{
            width: 1px;
            height: 1rem;
          }
          &:after{
            width: 1rem;
            height: 1px;
          }
        }
      }
    }
    ul{
      li{
        line-height: 1.3;
        font-weight: 700;
        a{
          display: block;
          @include fs(16);
          padding: 0.3rem ;
          @include fs(18);
          letter-spacing: 2px;

          @include mq-sp{
            padding: 0.8rem ;
          };
          &:hover{
            text-decoration: underline;
          }
        }
        ul{
          margin-left: 1rem;
          li{
            font-weight: 500;
            a{
              position: relative;
              padding-left: 1.4rem;
              @include mq-sp{
                padding: 0.5rem ;
              };
              &:before {
                left: 0;
                top: 50%;
                content: "";
                position: absolute;
                width: 1rem;
                height: 1px;
                background: #ccc;
              }
            }
          }
        }
      }
    }
  }
}

._article_relation{
  margin-top: 5rem;
  h2{
    padding: 1rem 0 3rem;
    border-top: 1px solid #ccc;
    display: flex;
    align-items: center;
    @include fs(22);
    position: relative;
    font-weight: 700;
    
    &:before{
      content: "";
      width: 2.5rem;
      height: 2.5rem;
      background-image: url("../images/column/ico_fukidashi_green.svg");
      background-repeat: no-repeat;
      background-size: cover;
    }
  }
}

._article_index_back{
  text-align: center;
  margin-top: 4rem;
  @include mq-sp{
    margin-top: 1rem;
  };
  a{
    font-size: 1.2rem;
    display: inline-block;
    color: #fff;
    background: $base-color-gureen;
    padding: 1.5rem 8rem;
    border: 1px solid $base-color-gureen;
    &:hover{
      background: #fff;
      color: $base-color-gureen;
    }
  }
}

._article_detail{
  h2,
  h3,
  p,
  figure {
    @include mgn(45, 0, 0);
    @include fs(20);
    letter-spacing: 2px;

    @include mq-sp{
      margin-top: 3rem;
    };
  }


  h2 {
    @include fs(28);
    font-weight: 900;
    position: relative;
    padding-left: 2rem;
    line-height: 1.5;
    &:before{
      border-radius: 100px;
      content: "";
      position: absolute;
      background: $base-color-gureen;
      width: 8px;
      height: 100%;
      left: 0%;
      top:50%;
      transform: translateY(-50%);
    }
  }

  h3 {
    @include fs(22);
    font-weight: 900;
    position: relative;
    padding-bottom: 0.7rem;
    &:before,
    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      height: 3px;
      background: #353535;
      border-radius: 100px;
    }

    &:before {
      background: $base-color-gureen;
      width: 11%;
      left: 0;
    }

    &:after {
      width: 88%;
      right: 0;
    }
  }

  p {
    color: #333;
  }
}

._topColumn{
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0rem 0 3rem;
  display: flex;
  gap:0 3rem;

  @include mq-sp{  
    flex-direction: column;
  };
  .__title{
    width: 25%;
    padding-top: 2rem;
    @include mq-sp{  
      width: 100%;
      padding-bottom: 3rem;
    };

    h2{
      @include fs(42);
      font-weight: 700;
      line-height: 1;
      span{
        padding-left: 1rem;
        @include fs(22);
      }
    }
    p{
      margin-top: 2rem;
      @include fs(18);
      font-weight: 300;
    }
  }
  ._article_contents{
    flex: 1;
  }
}